<template>
   <div>
    <div class="xlTableContainer">
      <b-row>
       <b-col cols="12">
        <div style="display: flex; justify-content:space-between;">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm ml-1 mt-1">Project Outcome</h3>
            </b-card-title>
          </div>
         <div style="display: flex; justify-content:space-between; gap:10px">
          <div>
            <div class="form-group">
              <label for="monthSelect" class="form-label"> Month:</label>
              <div class="select-container">
                <b-form-select id="monthSelect" v-model="selectedXlMonth" @input="getXLMonthChange"
                  class="custom-select">
                  <option v-for="month in months":value="month.value"
                    :key="month.value">
                    {{ month.label }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </div>
          <div>
            <div class="form-group">
              <label for="yearSelect" class="form-label"> Year:</label>
              <div class="select-container">
                <b-form-select id="yearSelect" v-model="selectedXLYear">
                  <option v-for="year in years" :value="year.label" :key="year.value">
                    {{ year.label }}
                  </option>
                </b-form-select>
              </div>
            </div>
          </div>
          <div style="margin-top: 23px;">
            <b-button
              variant="outline-primary"
              @click="openXlUpload"
              size="sm"
              style="padding: 10px;"
              >Upload
              <b-icon icon="cloud-upload" variant="primary" />
            </b-button>
          </div>
          <div style="margin-top: 23px;" v-if="Object.keys(tableDataXL).length > 0">
            <b-button
              v-b-tooltip.hover.top="'Delete your uploaded Excel file'"
              variant="outline-danger"
              @click="openXLDeleteModal"
              size="sm"
              style="padding: 10px;"
              >Delete
              <b-icon icon="trash-fill" variant="danger" />
            </b-button>
          </div>
         </div>
        </div>
       </b-col>
      </b-row>
      <div class="xlContainer">
        <div v-if="isXLDataFetching" class="spinnerCentered">
          <multiChannelSpinner/>
        </div>
        <div v-else-if="Object.keys(tableDataXL).length === 0" class="spinnerCentered">
          <span class="p-2">
            Data not found!
          </span>
        </div>
        <div v-else>
          <table>
            <tbody>
              <tr v-for="(row, rowIndex) in tableMatrix" :key="rowIndex">
                <td
                v-for="(cell, colIndex) in row"
                :key="colIndex"
                :style="`color: #${cell && cell.style.fontColor || '000000'}; background-color: ${cell &&  cell.style.bgColor ? '#' + cell.style.bgColor : 'transparent'}`"
                :class="getClass(cell)"
              >
              {{ cell && cell.value || '' }} 
              </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal
    v-model="uploadXLModal"
    id="showShopNameModal"
    hide-footer
    hide-header
    centered
    size="sm"
  >
    <b-card-text>
      <div style="display: flex; justify-content:space-between; padding:10px 0px">
        <h4 class="app-title mdsm">
          Upload Project Outcome
        </h4>
        <div class="header text-right">
          <feather-icon
            style="cursor: pointer"
            @click="closeXlUpload"
            class="Xicon"
            icon="XIcon"
          ></feather-icon>
        </div>
      </div>
    </b-card-text>
    <div  style="display: flex;gap:10px">
      <div class="form-group selectChannelBox" style="width: 400px !important;">
        <label for="monthSelect" class="form-label"> Month</label>
        <div class="select-container xl-modal-container">
          <b-form-select
            id="monthSelect"
            v-model="selectedMonth"
          >
            <option
              v-for="month in months"
              :value="month.value"
              :key="month.value"
            >
              {{ month.label }}
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="form-group selectChannelYearBox">
        <label for="monthSelect" class="form-label"> Year</label>
        <div class="select-container xl-modal-container-year">
          <b-form-select disabled id="monthSelect" v-model="selectedYear">
            <option v-for="year in year" :value="year" :key="year">
              {{ year }}
            </option>
          </b-form-select>
        </div>
      </div>
    </div>
    <div class="container">
        <div class="file-input-box">
          <div class="wrapper-file-input">
            <div class="input-box" @click="openFileInput">
              <h4>
                <i class="fa-solid fa-upload"></i>
                Choose XLSX Sheet to upload
              </h4>
              <input
                ref="fileInput"
                type="file"
                hidden
                @change="handleFileChange"
                 accept=".xls,.xlsx"
              />
            </div>
            <small>File Supported: XLSX Sheet</small>
          </div>
  
          <div style="margin-top:10px" class="wrapper-file-section">
            <div class="selected-files" v-if="selectedFileName">
              <small>Selected File</small>
              <ul class="file-list">
                <li class="item">
                  <span class="name">{{ selectedFileName.name }}</span>
                  <div class="remove" @click="removeFile">
                    <i class="fa-solid fa-trash-can"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
    </div>
        <div style="margin-bottom: 5px;">
          <b-button
            variant="primary"
            block
            @click="uploadXLProjectOutcome"
            size="sm"
            style="padding: 10px;"
            >Upload
            <b-icon icon="cloud-upload-fill" style="color: white;" />
            <b-spinner v-if="isUploadXLSheet" small class="ml-1" />
          </b-button>
        </div>
  </b-modal>
  <b-modal
  no-close-on-backdrop
  id="modal-center"
  hide-footer
  hide-header
  centered
  size="small"
  v-model="xlDeleteModal"
>
  <b-card-text>
    <div style="float: right">
      <b-button
        variant="flat-primary"
        @click="closeXLDeleteModal"
        style="margin-top: -10px; margin-right: -18px"
        ><feather-icon icon="XIcon"></feather-icon
      ></b-button>
    </div>
    <br />
    <div class="text-center" style="margin-top: 5px">
      <h4>Are sure want to delete this uploaded XLSX Sheet?</h4>
    </div>
    <div class="button-container">
      <b-button
        variant="outline-danger"
        class="custom-primary-with-border"
        @click="closeXLDeleteModal"
      >
        Cancel
      </b-button>
      <b-button variant="danger" @click="deleteUploadedSheet">
        Delete  <b-spinner v-if="isDeleteXLSXSheet" small class="ml-1" />
        </b-button>
    </div>
  </b-card-text>
</b-modal>
   </div>
    
  </template>
  
  <script>
  import axios from "axios";
  import { getUserToken } from "@/auth/utils";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import { BCol, BRow , BFormSelect,BCardTitle, BButton, BModal,BCardText,BSpinner} from 'bootstrap-vue';
  import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
  export default {
    data() {
      return {
        isDeleteXLSXSheet:false,
        uploadXLStatus:"",
        isUploadXLSheet:false,
        xlDeleteModal:false,
        selectedFileName: null,
        months: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      selectedYear: new Date().getFullYear(),
      year: [new Date().getFullYear()],
      selectedMonth: new Date().getMonth(),
      currentMonth: new Date().getMonth(),
      uploadXLModal:false,
      selectedXlMonth: new Date().getMonth(),
      selectedXLYear: new Date().getFullYear(),
      years: [{ label: "2024", value: 1 }],
      months: [
        { label: "January", value: 0 },
        { label: "February", value: 1 },
        { label: "March", value: 2 },
        { label: "April", value: 3 },
        { label: "May", value: 4 },
        { label: "June", value: 5 },
        { label: "July", value: 6 },
        { label: "August", value: 7 },
        { label: "September", value: 8 },
        { label: "October", value: 9 },
        { label: "November", value: 10 },
        { label: "December", value: 11 },
      ],
      };
    },
    props:{
      maxXLRow:{
        type: Number
      },
      maxXLCol:{
        type: Number
      },
      tableDataXL:{
        type:Object
      },
      isXLDataFetching:{
        type:Boolean
      },
      clientId:{
        type:String
      }
    },
    components:{
      ToastificationContent, BSpinner,  BCardText, BModal,BCol, BRow,BFormSelect,BCardTitle,multiChannelSpinner,BButton
    },
    computed: {
      tableMatrix() {
        const tableMatrix = Array.from({ length: this.maxXLRow }, () =>
    Array(this.maxXLCol).fill(null)
  );
  if (this.tableDataXL && this.tableDataXL.data) {
    this.tableDataXL.data.forEach((item) => {
      const { row, col } = this.getRowCol(item.position);
      // Check if row and col are within bounds.
      if (row < this.maxXLRow && col < this.maxXLCol) {
        tableMatrix[row][col] = item;
      } else {
        console.warn(`Row or column out of bounds: row ${row}, col ${col}`);
      }
    });
  }
  return tableMatrix;
      },
    },
    methods: {
      deleteUploadedSheet() {
      this.isDeleteXLSXSheet = true;
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/projectOutcome/data/?clientID=${this.clientId}&month=${this.selectedXlMonth}&year=${this.selectedXLYear}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.$emit("getXLReport");
          this.isDeleteXLSXSheet = false;
          this.showToast(
            "Success!",
            "Your uploaded Excel file has been successfully deleted.",
            "success"
          );
          this.xlDeleteModal = false
        })
        .catch((error) => {
          this.isDeleteSpends = false;
          this.xlDeleteModal = false
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
      openXLDeleteModal(){
        this.xlDeleteModal = true
      },
      closeXLDeleteModal(){
        this.xlDeleteModal = false
      },
      showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
 uploadXLProjectOutcome() {
  const formData = new FormData();
  formData.append("file", this.selectedFileName);
  formData.append("month", this.selectedMonth);
  formData.append("year", this.selectedYear);
  formData.append("clientID", this.clientId);
  this.isUploading = true;
  this.isUploadXLSheet = true;
  axios
    .post(
      `${process.env.VUE_APP_SERVER_URL}/api/projectOutcome/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUserToken()}`,
        },
      }
    )
    .then((response) => {
      this.uploadXLModal = false
      if (response.status === 200) {
        this.isUploading = false;
        this.isUploadXLSheet = false;
        this.showToast("Success!", response.data.message, "success");
      } else {
        throw new Error("File upload failed.");
      }
      this.$emit("getXLReport");
    })
    .catch((error) => {
      this.showToast("Error", "Unknown error occurred.", "danger");
      console.error("Error uploading file:", error);
      this.isUploading = false;
      this.uploadXLModal = false
      this.isUploadXLSheet = false;
    });
  }
,
   openFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0]; 
      if (file) {
        this.selectedFileName = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvContent = e.target.result;
          this.processCSV(csvContent);
        };
        reader.readAsText(file);
      }
    },
    processCSV(csvContent) {
      const rows = csvContent.split('\n').map(row => row.split(','));
      // Process the CSV rows as needed
    },
    removeFile() {
      this.selectedFileName = null; // Remove the selected file
      this.$refs.fileInput.value = ''; // Reset the input value
    },
    openXlUpload(){ 
          this.selectedFileName = null
          this.uploadXLModal = true
      },
    closeXlUpload(){ 
          this.selectedFileName = null
          this.uploadXLModal = false
      },
    getXLMonthChange(val) {
      this.$emit("getXLMonthOnChange", val);
      },
    getRowCol(position) {
        const col = position.charCodeAt(0) - 65;
        const row = parseInt(position.slice(1)) - 1;
        return { row, col };
      },
    getStyle(cell) {
      if (!cell) return {};
      const styles = {};
      if (cell.style.fontColor) styles.color = `#${cell.style.fontColor}`;
      if (cell.style.bgColor) styles.backgroundColor = `#${cell.style.bgColor}`;
      return styles;
    },
    getClass(cell) {
      if (!cell) return '';
      return {
        bold: cell.style.bold,
        italic: cell.style.italic,
        underline: cell.style.underline,
      };
    },
    },
  };
  </script>
  
  <style lang="scss">
  .xlTableContainer{
    table {
      border-spacing: 0;
      width: 100%;
      font-family: Arial, sans-serif; 
    }
    
    td, th {
      white-space: nowrap;
      border: 1px solid #ddd; 
      padding: 12px 15px;
      height: 45px;
      text-align: left;
      background-color: #f9f9f9; 
      color: #333;
      transition: background-color 0.3s ease; 
    }
    
    th {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #ddd; 
      font-weight: bold;
    }
    
    td {
      border: 1px solid #ddd; 
      background-color: white;
    }

    
    td:first-child, th:first-child {
      border-left: none;
    }
    
    td:last-child, th:last-child {
      border-right: none; 
    }
  
    table {
      border: 1px solid #ddd;
    }
  }

  .xl-modal-container{
    .custom-select {
      min-width: 200px;
  }
  }
  .xl-modal-container-year{
    .custom-select {
      min-width: 140px;
  }
  }
  .spinnerCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20px;
  }
  .xlContainer{
    overflow-x: auto; 
    -webkit-overflow-scrolling: touch; 
  }
  .custom-modal-width .modal-dialog {
    max-width: 1000px; /* Set your custom width here */
  }
    .file-input-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px;
    width: 355px;
    height: auto;
    background-color: #ffffff;
    margin-left: -12px;
    padding: 10px 0;

    .input-box {
      padding: 30px;
      display: grid;
      place-items: center;
      border: 2px dashed #cacaca;
      border-radius: 5px;
      margin-bottom: 5px;
      cursor: pointer;
      h4 {
        margin: 0;
      }
    }
    small {
      font-size: 12px;
      color: #a3a3a3;
    }
    .wrapper-file-section {
      .selected-files {
        h5 {
          margin-bottom: 10px;
        }
        .file-list {
          overflow-y: auto;
          list-style-type: none;
          padding: 0 10px 10px 0;
          width: 365px;
          margin: 0;
          transition: 0.3s all ease-in-out;
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #cacaca;
            border-radius: 5px;
            padding: 10px;
            font-size: 14px;

            &:not(:last-child) {
              margin-bottom: 5px;
            }
            .remove {
              display: grid;
              place-items: center;
              color: #c0392b;
              cursor: pointer;
              transition: 0.3s all ease-in-out;
              &:hover {
                color: #e74c3c;
              }
            }
          }
        }
      }
    }
  }

  </style>
  